import { useStorageDao } from "@/dao/storage-dao";
import { authStorage } from "@/plugins/nhost";

const storageDao = useStorageDao();

const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";
const USER_ID = "user_id";
const WHATS_NEW_SEEN_CONTENT = "whats_new_seen_content";

function getAccessToken(): string | null {
  return storageDao.get(ACCESS_TOKEN) || null;
}

function setAccessToken(value: string) {
  storageDao.set(ACCESS_TOKEN, value);
}

function getRefreshToken(): string | null {
  return storageDao.get(REFRESH_TOKEN);
}

function setRefreshToken(value: string) {
  storageDao.set(REFRESH_TOKEN, value);
}

function setUserId(value: string) {
  storageDao.set(USER_ID, value);
}

function removeAccessToken() {
  storageDao.remove(ACCESS_TOKEN);
}

function removeRefreshToken() {
  storageDao.remove(REFRESH_TOKEN);
}

function removeUserId() {
  storageDao.remove(USER_ID);
}

function getUserId(): string | null {
  return storageDao.get(USER_ID);
}

function getLinkFromUid(fileId: string) {
  return authStorage.getPublicUrl({ fileId });
}

function getWhatsNewSeenContent(): string {
  return storageDao.get(WHATS_NEW_SEEN_CONTENT);
}

function setWhatsNewSeenContent(value: string) {
  storageDao.set(WHATS_NEW_SEEN_CONTENT, `${value}`);
}

async function getFile(uuid: string, name?: string) {
  const res = await fetch(getLinkFromUid(uuid), {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  const blob = await res.blob();
  const file = new File([blob], name || "image", {
    type: blob.type,
  });
  return file;
}

function uploadFile(file: File) {
  return authStorage.upload({ file });
}

function deleteFile(fileId: string) {
  return authStorage.delete({ fileId });
}

function setToken(token: string) {
  return authStorage.setAccessToken(token);
}

export function useStorageService() {
  return {
    getAccessToken,
    setAccessToken,
    getRefreshToken,
    setRefreshToken,
    setUserId,
    removeAccessToken,
    removeRefreshToken,
    removeUserId,
    getUserId,
    getLinkFromUid,
    uploadFile,
    setToken,
    getFile,
    deleteFile,
    getWhatsNewSeenContent,
    setWhatsNewSeenContent,
  };
}
