import { createRouter, createWebHistory } from "vue-router";
import privateRoutes from "./routes/private";
import publicRoutes from "./routes/public";
import { useBaseStore } from "@/store/base";
import { authGuard } from "./guards/auth-guard";

const router = createRouter({
  history: createWebHistory(),
  routes: [...publicRoutes, ...privateRoutes],
});
router.beforeEach((to, from, next) => {
  if (useBaseStore().isApplicationReady) {
    authGuard(to, from, next);
  } else {
    useBaseStore().$subscribe((mutation, state) => {
      if (state.isApplicationReady) {
        authGuard(to, from, next);
      }
    });
  }
});

export default router;
