import {HasuraStorageClient} from "@nhost/hasura-storage-js";
import {HasuraAuthClient} from "@nhost/hasura-auth-js";

export const authClient = new HasuraAuthClient({
  url: `${import.meta.env.VITE_NHOST_AUTH_BACKEND_URL}`,
  autoRefreshToken: true,
  autoSignIn: true,
  autoLogin: true,
})

export const authStorage = new HasuraStorageClient({
  url: `${import.meta.env.VITE_NHOST_STORAGE_BACKEND_URL}/v1`,
});
