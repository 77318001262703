<template>
  <div
    id="profile"
    @click="router.push('/profile')"
    class="d-flex flex-row align-center rounded-lg clickable"
    :class="isOnProfilPage ? 'bg-primary-500' : ''"
  >
    <v-col class="pa-0 ma-0 mr-6" cols="auto" v-if="imageUrl">
      <v-img
        class="navbar-profile-pic rounded-lg"
        :src="imageUrl"
        alt="Logo"
        :height="40"
        :width="40"
      />
    </v-col>
    <v-col v-if="!rail" class="pa-0 ma-0" :class="imageUrl ? '' : 'pl-6'">
      <div class="navbar-profile-username">
        {{ auth?.user?.fullName }}
      </div>
      <div class="navbar-profile-role">
        {{ t(`enum.user_role.${auth?.user?.role.toUpperCase()}`) }}
      </div>
    </v-col>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from "vue";
import { useAuthStore } from "@/store/auth";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStorageService } from "@/services/storage-service";

const imageUrl = ref(null);
const auth = useAuthStore();
const router = useRouter();
const { t } = useI18n();
const storageService = useStorageService();

defineProps<{
  rail: boolean;
}>();

onMounted(async () => {
  imageUrl.value = await getImageUrl();
});

watch(
  () => auth.user?.imageId,
  async () => {
    imageUrl.value = await getImageUrl();
  }
);

const isOnProfilPage = computed(() => {
  return router.currentRoute.value.path === "/profile";
});

async function getImageUrl() {
  if (auth.user?.imageId) {
    return URL.createObjectURL(
      await storageService.getFile(auth.user?.imageId)
    );
  } else {
    return null;
  }
}
</script>

<style scoped>
.navbar-profile-username {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(var(--v-theme-primary-100));
}

.navbar-profile-role {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgb(var(--v-theme-primary-300));
}

.clickable:hover {
  cursor: pointer;
  background-color: rgb(var(--v-theme-primary-500));
}
</style>
