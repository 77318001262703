// Styles
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

import {
  VApp,
  VBtn,
  VFileInput,
  VTextField,
  VCol,
  VRow,
  VIcon,
  VImg,
  VDialog,
  VCard,
  VCardTitle,
  VCardItem,
  VCardSubtitle,
  VBreadcrumbs,
  VProgressCircular,
  VMenu,
  VChip,
  VTable,
  VList,
  VLayout,
  VListGroup,
  VListItem,
  VListItemTitle,
  VExpandTransition,
  VInput,
  VContainer,
  VPagination,
  VChipGroup,
  VSelect,
  VForm,
  VCheckbox,
  VCardText,
  VCardActions,
  VMain,
  VSlideYTransition,
  VSwitch,
  VNavigationDrawer,
  VDivider,
  VAutocomplete,
  VTooltip,
  VDatePicker,
  VTabs,
  VTab,
  VWindow,
  VWindowItem,
  VBtnToggle,
  VVirtualScroll,
} from "vuetify/components";

// Vuetify
import { createVuetify, type ThemeDefinition } from "vuetify";
import { mdi } from "vuetify/iconsets/mdi";
import { VTabsWindow } from "vuetify/lib/components/index.mjs";
import { VTabsWindowItem } from "vuetify/lib/components/index.mjs";

const colors = {
  "primary-900": "#020614",
  "primary-700": "#091220",
  "primary-600": "#0C1524",
  "primary-500": "#1D2636",
  "primary-400": "#242D3D",
  "primary-350": "#2C374C",
  "primary-300": "#A1A2A8",
  "primary-100": "#FFFFFF",

  "tertiary-500": "#483E17",
  "tertiary-900": "#FFF100",

  "secondary-900": "#1E508C",

  "accent-900": "#FF2E2E",
  "accent-700": "#8CE59E",
  "accent-500": "#285FFF",
  "accent-300": "#101D13",

  "custom-orange": "#c49c24",

  "typography-700": "#A1A2A8",

  "yellow-100": "#362F14",
};

const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    surface: colors["primary-700"],
    ...colors,
  },
  variables: {
    "border-radius-root": 8,
    "base-spacing": 8,
  },
};

export default createVuetify({
  components: {
    VApp,
    VBtn,
    VTextField,
    VTable,
    VBreadcrumbs,
    VExpandTransition,
    VIcon,
    VSwitch,
    VDatePicker,
    VFileInput,
    VSelect,
    VCol,
    VChipGroup,
    VRow,
    VMenu,
    VList,
    VLayout,
    VChip,
    VInput,
    VListItem,
    VListGroup,
    VContainer,
    VListItemTitle,
    VImg,
    VForm,
    VDialog,
    VCard,
    VCardTitle,
    VCardItem,
    VCardSubtitle,
    VProgressCircular,
    VCheckbox,
    VPagination,
    VCardText,
    VCardActions,
    VMain,
    VSlideYTransition,
    VNavigationDrawer,
    VDivider,
    VAutocomplete,
    VTooltip,
    VTabs,
    VTab,
    VWindow,
    VWindowItem,
    VBtnToggle,
    VVirtualScroll,
    VTabsWindow,
    VTabsWindowItem,
  },
  directives: {},
  theme: {
    defaultTheme: "lightTheme",
    themes: {
      lightTheme,
    },
  },
  icons: {
    defaultSet: "mdi",
    sets: {
      mdi,
    },
  },
});
