import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { redirect } from "@/services/auth-service/utils";
import { useAuthService } from "@/services/auth-service";

export const authGuard = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const authService = useAuthService();
  const isSignedIn = await authService.isSignedInAsync();
  const role = authService.getSession()?.user?.defaultRole;

  if (to.meta?.isSecured && !isSignedIn) {
    next({ path: "/sign-in", replace: true });
  } else if (["/create-account", "/sign-in"].includes(to.path) && isSignedIn) {
    next({ path: redirect(role), replace: true });
  } else {
    const allowedRolesTo: string[] = (to.meta?.allowedRoles as string[]) || [];
    if (!allowedRolesTo.includes(role) && to.meta?.isSecured) {
      next(redirect(role));
    } else {
      next();
    }
  }
};
