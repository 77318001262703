import { watch } from "vue";
import { NhostSession, SignUpOptions } from "@nhost/hasura-auth-js";

import { useAuthStore } from "@/store/auth";
import { authClient } from "@/plugins/nhost";
import { useAuthDao } from "@/dao/auth-dao";
import { useStorageService } from "@/services/storage-service";

const authDao = useAuthDao();
const storageService = useStorageService();

function isSignedIn(): boolean {
  return authDao.isSignedIn();
}

function isSignedInAsync(): Promise<boolean> {
  return authDao.isSignedInAsync();
}

function getSession(): NhostSession | null {
  return authDao.getSession();
}

async function refreshTokenAndGetAccessToken(): Promise<String> {
  const res = await refreshToken();

  await saveTokenFromSession();

  return res.session?.accessToken;
}

function refreshToken() {
  return authClient.refreshSession(storageService.getRefreshToken());
}

function accessToken(): String | null {
  return storageService.getAccessToken();
}

async function signIn(email: string, password: string) {
  await authDao.signIn(email, password);
}

async function register(
  email: string,
  password: string,
  options?: SignUpOptions
) {
  await authDao.register(email, password, options);
}

async function saveTokenFromSession() {
  const session = getSession();

  if (session) {
    storageService.setAccessToken(session.accessToken);
    storageService.setRefreshToken(session.refreshToken);

    if (session.user) {
      const userId = session.user.id;
      storageService.setUserId(userId);
    }
  }
}

async function signOut(): Promise<void> {
  await authDao.signOut();

  storageService.removeAccessToken();
  storageService.removeRefreshToken();
  storageService.removeUserId();

  useAuthStore().setUser(undefined);
}

async function refreshSession() {
  return await authClient.refreshSession();
}

async function changePassword(newPassword: string) {
  await authDao.changePassword(newPassword);
}

async function resetPassword(email: string) {
  await authDao.resetPassword(email);
}

export function useAuthService() {
  return {
    isSignedIn,
    getSession,
    refreshTokenAndGetAccessToken,
    accessToken,
    signIn,
    register,
    saveTokenFromSession,
    signOut,
    changePassword,
    refreshSession,
    resetPassword,
    isSignedInAsync,
  };
}
