import { defineStore } from "pinia";
import { useAuthService } from "@/services/auth-service";
import { User, UserRole } from "@/services/user-service";
import { authClient } from "@/plugins/nhost";
import { CompanyFunctionnalityEnum } from "@/services/company-service/models";

const authService = useAuthService();

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      user: undefined as User,
    };
  },
  actions: {
    setUser(user: User) {
      this.user = user;
    },
    async signIn(email: string, password: string) {
      return authService.signIn(email, password);
    },
    async register(email: string, password: string) {
      return authService.register(email, password);
    },
    async signOut() {
      return authService.signOut();
    },
    isAppAdmin() {
      if (this.user === null) {
        return false;
      }
      return this.user?.role === UserRole.APP_ADMIN;
    },
    isAnyCompanyAdmin(): boolean {
      if (this.user === null) {
        return false;
      }
      return (
        this.user?.role === UserRole.COMPANY_ADMIN ||
        this.user?.role === UserRole.COMPANY_ADMIN_DASHBOARD ||
        this.user?.role === UserRole.COMPANY_ADMIN_CHECKLIST
      );
    },
    isCompanyAdmin(): boolean {
      if (this.user === null) {
        return false;
      }
      return this.user?.role === UserRole.COMPANY_ADMIN;
    },
    isCompanyAdminDashboard(): boolean {
      if (this.user === null) {
        return false;
      }

      const companyFunctionalities = authClient.getHasuraClaim(
        "company-functionalities"
      );
      return (
        this.user?.role === UserRole.COMPANY_ADMIN_DASHBOARD ||
        (this.isCompanyAdmin() &&
          companyFunctionalities.includes(CompanyFunctionnalityEnum.DASHBOARD))
      );
    },
    isCompanyAdminChecklist(): boolean {
      if (this.user === null) {
        return false;
      }

      const companyFunctionalities = authClient.getHasuraClaim(
        "company-functionalities"
      );
      return (
        this.user?.role === UserRole.COMPANY_ADMIN_CHECKLIST ||
        (this.isCompanyAdmin() &&
          companyFunctionalities.includes(CompanyFunctionnalityEnum.CHECKLIST))
      );
    },
    isInstaller(): boolean {
      if (this.user === null) {
        return false;
      }
      return this.user?.role === UserRole.INSTALLER;
    },
    isDriver(): boolean {
      if (this.user === null) {
        return false;
      }
      return this.user?.role === UserRole.DRIVER;
    },
    isSiteManager(): boolean {
      if (this.user === null) {
        return false;
      }
      return this.user?.role === UserRole.SITE_MANAGER;
    },
    isStandardUser(): boolean {
      if (this.user === null) {
        return false;
      }
      return this.user?.role === UserRole.STANDARD_USER;
    },
    isLicenceManager() {
      if (this.user === null) {
        return false;
      }
      return this.user?.role === UserRole.LICENCE_MANAGER;
    },
    isLinkedToCompany(): boolean {
      if (this.user === null) {
        return false;
      }
      return (
        this.isCompanyAdmin() ||
        this.isCompanyAdminDashboard() ||
        this.isCompanyAdminChecklist() ||
        this.isSiteManager() ||
        this.isStandardUser()
      );
    },
  },
});
