import { AvailableLanguages } from "@/locales/locales-models";
import { defineStore } from "pinia";

export const useBaseStore = defineStore("base", {
  state: () => {
    return { isApplicationReady: false};
  },
  actions: {
    appReady() {
      this.isApplicationReady = true;
    },
  },
});
