import { mapCompany } from "../company-service/mapper";
import { Planning, Schedule } from "./model";

export function mapSchedule(data: any): Schedule {
  if (!data) {
    return null;
  }

  return {
    uuid: data.id,
    planning_id: data.planning_id,
    day: data?.day,
    hour: data?.hour,
  };
}

export function mapSchedules(data: any): Schedule[] {
  return data?.map(mapSchedule) || [];
}

export function mapPlanning(data: any): Planning {
  if (!data) {
    return null;
  }
  return {
    uuid: data.id,
    name: data.name,
    company: mapCompany(data.company),
    schedules: mapSchedules(data.schedules),
  };
}

export function mapPlannings(data: any): Planning[] {
  return data?.map(mapPlanning);
}
