import { GeoResult } from "@/helpers/geo-utils";
import { Company, CompanyFunctionality } from "./models";

export function mapCompany(data: any): Company {
  return {
    uuid: data?.uuid,
    name: data?.name,
    address: GeoResult.mapForCompany(data),
    logo_id: data?.logo_id,
    imageName: data?.image?.name,
    blurSetting: data?.blur_setting,
    functionalities: mapCompanyFunctionalities(data?.functionalities?.map((el) => el.functionality)),
  };
}

export function mapCompanies(data: any): Company[] {
  return data?.companies?.map(mapCompany) || [];
}

export function mapCompanyFunctionality(data: any): CompanyFunctionality {
  return {
    id: data?.id,
    key: data?.key,
  };
}

export function mapCompanyFunctionalities(data: any): CompanyFunctionality[] {
  return data?.map(mapCompanyFunctionality) || [];
}
