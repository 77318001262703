import { mapCompany } from "../company-service/mapper";
import { mapVehicle } from "../vehicle-service/mapper";
import { Product, ProductConfig } from "./models";

export function mapProductConfig(data: any): ProductConfig {
  if (!data) {
    return null;
  }

  return {
    softwareVersion: data?.softwareVersion,
    hasShockDetection: data?.hasShockDetection || false,
    verticalTreshold: data?.verticalTreshold || -1,
    horizontalTreshold: data?.horizontalTreshold || -1,
  };
}
export function mapProduct(data: any): Product {
  if (!data) {
    return null;
  }

  return {
    id: data?.id,
    serialNumber: data?.serial_number,
    status: data?.status,
    createdAt: data?.created_at,
    vehicleId: data?.vehicle_id,
    unregisteredAt: data?.unregistered_at,
    vehicle: mapVehicle(data?.vehicle),
    company: mapCompany(data?.company),
    registeredAt: data?.registered_at,
  };
}

export function mapProducts(data: any): Product[] {
  if (!data) {
    return [];
  }
  return data?.map(mapProduct);
}
