import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/create-account",
    name: "CreateAccount",
    component: () => import("@/views/CreateAccount.vue"),
    meta: { title: "create-account.title", isSecured: false, hideDrawer: true },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue"),
    meta: {
      title: "reset-password.title",
      isSecured: false,
      hideDrawer: true,
    },
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("@/views/SignIn.vue"),
    meta: { title: "sign-in.title", isSecured: false, hideDrawer: true },
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
    meta: { title: "404.title", isSecured: false },
  },
  {
    path: "/:catchAll(.*)",
    name: "jump",
    meta: {
      secure: false,
      hideDrawer: true,
    },
    component: () => import("@/views/Jump.vue"),
  },
] as RouteRecordRaw[];
