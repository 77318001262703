<template>
  <v-dialog v-model="isDialogOpen" :width="700">
    <v-card class="bg-primary-500 py-2" rounded="lg">
      <v-card-text class="px-4">
        <v-container>
          <v-row class="d-flex justify-space-between mb-3">
            <h2 class="pb-2">{{ $t("whatsnew.title") }}</h2>
            <v-icon
              icon="mdi-close"
              color="primary-100"
              size="24px"
              @click="close"
            />
          </v-row>
          <v-row class="pb-6">
            <div v-for="(text, index) in $t('whatsnew.text').split('\n')" :key="index" class="mb-2">
              {{ text }}
            </div>
          </v-row>
          <v-row>
            <v-col class="d-flex">
              <AppButton
                :text="$t('whatsnew.close')"
                class="pt-4 pb-8 flex-grow-1"
                variant="outlined"
                color="primary-300"
                @click="close"
              />
            </v-col>
            <v-col class="d-flex">
              <AppButton
                :text="$t('whatsnew.acknowledge')"
                class="pt-4 pb-8 flex-grow-1"
                @click="acknowledge"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import AppButton from "@/components/atoms/AppButton.vue";

const emit = defineEmits(["acknowledge"]);

const isDialogOpen = ref(true);

const close = () => {
  isDialogOpen.value = false
};

const acknowledge = () => {
  emit('acknowledge')
  isDialogOpen.value = false
};
</script>
<style scoped lang="scss">
.v-list-item :deep(.v-list-item__prepend .v-list-item__spacer) {
  width: 12px;
}

.v-list-group--open {
  .v-list-header {
    background-color: rgb(var(--v-theme-primary-500));
  }
  .navbar-item .navbar-item-text {
    color: white;
  }

  .active {
    width: auto;
    margin-inline: 6px;
    border-left: solid;
    border-color: rgba(var(--v-theme-tertiary-900), 1);
  }

  .active::after {
    content: " ";
    display: inline-block;
  }
}

.navbar-item {
  padding: calc(var(--v-base-spacing) * 1px) calc(var(--v-base-spacing) * 3px);
  border-radius: calc(var(--v-border-radius-root) * 1px);

  .navbar-item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: rgba(var(--v-theme-primary-100), 0.7);
  }

  &:hover {
    background-color: rgb(var(--v-theme-primary-500));
    cursor: pointer;
  }
}
</style>
