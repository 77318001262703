import { GeoResult } from "@/helpers/geo-utils";

export enum CompanyBlurSettings {
  HIDDEN = "HIDDEN",
  BLURRED = "BLURRED",
  ORIGINAL = "ORIGINAL",
}

export enum CompanyFunctionnalityEnum {
  CHECKLIST = "checklist",
  DASHBOARD = "dashboard",
}


export interface Company {
  uuid: string;
  name: string;
  address: GeoResult;
  logo_id: string;
  imageName: string;
  blurSetting?: CompanyBlurSettings;
  functionalities?: CompanyFunctionality[]
}

export interface CompanyFunctionality {
  id: string;
  key: string;
}

export interface CompanyForm {
  name: string;
  address: GeoResult;
  image: File;
  logo_id?: string;
  blurSetting: CompanyBlurSettings;
  functionalities: CompanyFunctionality[]
}
