<template>
  <TransitionGroup name="list" tag="p" class="toast-container">
    <Toast
      v-for="tag, i in toastStore.toasts"
      :id="`toast-${i}`"
      class="list-item"
      :key="tag.uuid"
      :message="tag.message"
      :icon="tag.icon"
      :type="tag.type"
      />
  </TransitionGroup>
</template>
<script setup lang="ts">
import Toast from '@/components/atoms/Toast.vue';

import { useToastStore } from '@/store/toast';

const toastStore = useToastStore();
</script>
<style scoped lang="scss">


.toast-container {
  position: fixed;

  bottom: calc(var(--v-base-spacing) * 1px);
  right: 50%;
  transform: translateX(50%);

  z-index: 9999;

  display: flex;
  flex-direction: column;
  gap: calc(var(--v-base-spacing) * 2px);
}

.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  //transform: translateY(30px);
}
</style>
