import { createI18n } from "vue-i18n";
import en from "../locales/en.json";
import fr from "../locales/fr.json";
import es from "../locales/es.json";
import de from "../locales/de.json";

import { getLocal } from "@/locales/locales-models";

export const i18n = createI18n({
  legacy: false,
  locale: getLocal(),
  fallbackLocale: "en",
  warnHtmlMessage: false,
  messages: {
    fr: fr,
    en: en,
    es: es,
    de: de,
  },
});
