export class PostgisPoint {
  type: string;
  coordinates: number[];

  static mapFromGoogleSearch(data: any): PostgisPoint {
    if (!data || (data?.loc?.lat && data?.loc?.lng)) {
      return null;
    }

    const point = new PostgisPoint();
    point.type = "Point";
    point.coordinates = [data?.lng || 0, data?.lat || 0];

    return point;
  }
}

export class GeoResult {
  place_id: string;
  country: string;
  city: string;
  address: string;
  street: string;
  street_number: string;
  location: PostgisPoint;

  static mapForCompany(data: any): GeoResult {
    if (!data || !data?.address) {
      return null;
    }
    const geoRes = new GeoResult();

    geoRes.place_id = data?.place_id || null;
    geoRes.country = data?.country || null;
    geoRes.city = data?.city || null;
    geoRes.address = data?.address || null;
    geoRes.street = data?.street || null;
    geoRes.street_number = data?.street_number || null;
    geoRes.location = data?.coordinates || null;

    return geoRes;
  }

  static mapForWorksite(data: any): GeoResult {
    if (!data || !data?.address) {
      return null;
    }
    const geoRes = new GeoResult();

    geoRes.place_id = data?.place_id || null;
    geoRes.country = data?.country || null;
    geoRes.city = data?.city || null;
    geoRes.address = data?.address || null;
    geoRes.street = data?.street || null;
    geoRes.street_number = data?.street_number || null;
    geoRes.location = data?.coordinates || null;

    return geoRes;
  }
}
