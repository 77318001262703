<template>
  <v-dialog v-model="value" width="auto" @update:model-value="emit('update:modelValue', $event)" persistent >
    <v-card class="bg-primary-700 rounded-lg pa-6" id="tos-dialog" style="max-width: 600px">
      <h2>{{ t("tos.title") }}</h2>
      <p class="mt-3" v-html="t('tos.content')"/>
      <div class="d-flex flex-row justify-end mt-6">
        <AppButton :text="t('tos.refuse')" class="mr-2" color="tertiary-900" @click="refuse()" id="refuse" variant="plain"/>
        <AppButton :text="t('tos.accept')" class="mr-2" color="tertiary-900" @click="accept()" id="accept" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import AppButton from "@/components/atoms/AppButton.vue";

import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ToastType, useToastStore } from "@/store/toast";

const emit = defineEmits(["update:modelValue", "accept", "refuse"]);
const toasts = useToastStore();
const { t } = useI18n();

const props = defineProps<{
  modelValue: boolean;
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

function refuse() {
  value.value = false;
  toasts.addToast(t("tos.refuse-toast"), ToastType.ERROR);
  emit("refuse");
}

function accept() {
  value.value = false;
  emit("accept");
}
</script>
