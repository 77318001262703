export enum AvailableLanguages {
  EN = "en",
  FR = "fr",
  ES = "es",
  DE = "de",
}

export function getLocal() {
  const locale = localStorage.getItem("i18n-locale");

  if (
    !locale ||
    ![
      AvailableLanguages.EN.valueOf(),
      AvailableLanguages.FR.valueOf(),
      AvailableLanguages.ES.valueOf(),
      AvailableLanguages.DE.valueOf(),
    ].includes(locale)
  ) {
    return AvailableLanguages.EN;
  }
  return locale;
}
