<template>
  <div
    id="ressources"
    @click="router.push('/ressources')"
    class="d-flex flex-row align-center rounded-lg clickable"
    :class="`${isOnRessourcePage ? 'bg-primary-500' : ''} 
    ${rail ? 'pa-1 justify-center' : 'pa-4'}`"
  >
    <v-icon :icon="ressourcesRoute.icon" size="20" />
    <p class="ml-3" v-if="!rail">
      {{ ressourcesRoute.title }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const router = useRouter();
const { t } = useI18n();

defineProps<{
  rail: boolean;
}>();

const ressourcesRoute = computed(() => {
  return {
    id: "ressources",
    title: t("drawer.ressources"),
    icon: "mdi-help",
    to: "/ressources",
  };
});

const isOnRessourcePage = computed(() => {
  return router.currentRoute.value.path === "/ressources";
});
</script>

<style scoped>
.navbar-profile-username {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(var(--v-theme-primary-100));
}

.navbar-profile-role {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgb(var(--v-theme-primary-300));
}

.clickable:hover {
  cursor: pointer;
  background-color: rgb(var(--v-theme-primary-500));
}
</style>
