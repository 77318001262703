import { UserRole } from "@/services/user-service";
import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/set-password",
    name: "SetPassword",
    component: () => import("@/views/SetPassword.vue"),
    meta: {
      title: "set-password.title",
      isSecured: true,
      hideDrawer: true,
      allowedRoles: [
        UserRole.APP_ADMIN,
        UserRole.COMPANY_ADMIN,
        UserRole.COMPANY_ADMIN_CHECKLIST,
        UserRole.COMPANY_ADMIN_DASHBOARD,
        UserRole.INSTALLER,
        UserRole.DRIVER,
        UserRole.SITE_MANAGER,
        UserRole.STANDARD_USER,
      ],
    },
  },
  {
    path: "/",
    name: "DashboardRoot",
    component: () => import("@/views/Dashboard.vue"),
    meta: {
      title: "dashboard.title",
      isSecured: true,
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
    meta: {
      title: "profile",
      isSecured: true,
      allowedRoles: [
        UserRole.APP_ADMIN,
        UserRole.COMPANY_ADMIN,
        UserRole.COMPANY_ADMIN_CHECKLIST,
        UserRole.COMPANY_ADMIN_DASHBOARD,
        UserRole.INSTALLER,
        UserRole.DRIVER,
        UserRole.SITE_MANAGER,
        UserRole.STANDARD_USER,
        UserRole.LICENCE_MANAGER,
      ],
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard.vue"),
    meta: {
      isSecured: true,
      title: "dashboard.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/checklists",
    name: "Checklists",
    component: () => import("@/views/Checklists.vue"),
    meta: {
      isSecured: true,
      title: "checklists.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/checklist",
    name: "CreateChecklist",
    component: () => import("@/views/Checklist.vue"),
    meta: {
      isSecured: true,
      title: "create-checklist.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/checklist/:uuid",
    name: "EditChecklist",
    component: () => import("@/views/Checklist.vue"),
    meta: {
      isSecured: true,
      title: "edit-checklist.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/companies",
    name: "Companies",
    component: () => import("@/views/Companies.vue"),
    meta: {
      isSecured: true,
      title: "companies.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.STANDARD_USER, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/company/:uuid",
    name: "EditCompany",
    component: () => import("@/views/Company.vue"),
    meta: {
      isSecured: true,
      title: "edit-company.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST, UserRole.COMPANY_ADMIN_DASHBOARD],
    },
  },
  {
    path: "/company",
    name: "CreateCompany",
    component: () => import("@/views/Company.vue"),
    meta: {
      isSecured: true,
      title: "create-company.title",
      allowedRoles: [UserRole.APP_ADMIN],
    },
  },
  {
    path: "/companies",
    name: "Companies",
    component: () => import("@/views/Companies.vue"),
    meta: {
      isSecured: true,
      title: "companies.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.STANDARD_USER, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/questions",
    name: "Questions",
    component: () => import("@/views/Questions.vue"),
    meta: {
      isSecured: true,
      title: "questions.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/question",
    name: "CreateQuestion",
    component: () => import("@/views/Question.vue"),
    meta: {
      isSecured: true,
      title: "create-question.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/question/:uuid",
    name: "EditQuestion",
    component: () => import("@/views/Question.vue"),
    meta: {
      isSecured: true,
      title: "edit-question.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: () => import("@/views/Reports.vue"),
    meta: {
      isSecured: true,
      title: "reports.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/report/:uuid",
    name: "EditReport",
    component: () => import("@/views/Report.vue"),
    meta: {
      isSecured: true,
      title: "report.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/segments",
    name: "Segments",
    component: () => import("@/views/Segments.vue"),
    meta: {
      isSecured: true,
      title: "checklists.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/segment",
    name: "CreateSegment",
    component: () => import("@/views/Segment.vue"),
    meta: {
      isSecured: true,
      title: "create-segment.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/segment/:uuid",
    name: "EditSegment",
    component: () => import("@/views/Segment.vue"),
    meta: {
      isSecured: true,
      title: "edit-segment.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/Users.vue"),
    meta: {
      isSecured: true,
      title: "checklists.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.STANDARD_USER, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/user",
    name: "CreateUser",
    component: () => import("@/views/User.vue"),
    meta: {
      isSecured: true,
      title: "create-user.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/user/:uuid",
    name: "EditUser",
    component: () => import("@/views/User.vue"),
    meta: {
      isSecured: true,
      title: "edit-user.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST, UserRole.COMPANY_ADMIN_DASHBOARD],
    },
  },
  {
    path: "/vehicles",
    name: "Vehicles",
    component: () => import("@/views/Vehicles.vue"),
    meta: {
      isSecured: true,
      title: "checklists.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.STANDARD_USER, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/vehicle-details/:uuid",
    name: "VehicleDetails",
    component: () => import("@/views/VehicleDetails.vue"),
    meta: {
      isSecured: true,
      title: "vehicles.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.STANDARD_USER, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/vehicle",
    name: "CreateVehicle",
    component: () => import("@/views/Vehicle.vue"),
    meta: {
      isSecured: true,
      title: "create-vehicle.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/vehicle/:uuid",
    name: "EditVehicle",
    component: () => import("@/views/Vehicle.vue"),
    meta: {
      isSecured: true,
      title: "edit-vehicle.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/vehicles-types",
    name: "VehicleTypes",
    component: () => import("@/views/VehicleTypes.vue"),
    meta: {
      isSecured: true,
      title: "vehicle-types.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.COMPANY_ADMIN_CHECKLIST, UserRole.STANDARD_USER, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/vehicles-type",
    name: "CreateVehicleType",
    component: () => import("@/views/VehicleType.vue"),
    meta: {
      isSecured: true,
      title: "create-vehicle-type.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/vehicles-types/:uuid",
    name: "EditVehicleType",
    component: () => import("@/views/VehicleType.vue"),
    meta: {
      isSecured: true,
      title: "edit-vehicle-type.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/worksites",
    name: "Worksites",
    component: () => import("@/views/Worksites.vue"),
    meta: {
      isSecured: true,
      title: "worksites.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.STANDARD_USER, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/worksite-details/:uuid",
    name: "WorksiteDetails",
    component: () => import("@/views/WorksiteDetails.vue"),
    meta: {
      isSecured: true,
      title: "worksite.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.STANDARD_USER, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/worksite",
    name: "CreateWorksite",
    component: () => import("@/views/Worksite.vue"),
    meta: {
      isSecured: true,
      title: "worksite.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_DASHBOARD],
    },
  },
  {
    path: "/worksite/:uuid",
    name: "EditWorksite",
    component: () => import("@/views/Worksite.vue"),
    meta: {
      isSecured: true,
      title: "worksite.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("@/views/Products.vue"),
    meta: {
      isSecured: true,
      title: "products.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.STANDARD_USER, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/product",
    name: "Product",
    component: () => import("@/views/Product.vue"),
    meta: {
      isSecured: true,
      title: "product.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/risk-management",
    name: "RiskManagement",
    component: () => import("@/views/RiskManagement.vue"),
    meta: {
      isSecured: true,
      title: "risk-management.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.STANDARD_USER, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/ressources",
    name: "Ressource",
    component: () => import("@/views/Ressources.vue"),
    meta: {
      isSecured: true,
      title: "ressources.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/safety",
    name: "Safety",
    component: () => import("@/views/Safety.vue"),
    meta: {
      isSecured: true,
      title: "safety.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.STANDARD_USER, UserRole.SITE_MANAGER],
    },
  },
  {
    path: "/plannings",
    name: "Plannings",
    component: () => import("@/views/Plannings.vue"),
    meta: {
      isSecured: true,
      title: "plannings.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/planning/:uuid",
    name: "Planning",
    component: () => import("@/views/Planning.vue"),
    meta: {
      isSecured: true,
      title: "planning.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_CHECKLIST],
    },
  },
  {
    path: "/request/",
    name: "Request",
    component: () => import("@/views/Request.vue"),
    meta: {
      isSecured: true,
      title: "request.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.LICENCE_MANAGER],
    },
  },
  {
    path: "/requests/",
    name: "Requests",
    component: () => import("@/views/Requests.vue"),
    meta: {
      isSecured: true,
      title: "requests.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.LICENCE_MANAGER],
    },
  },
  {
    path: "/subscriptions-licenses/",
    name: "SubscriptionsLicenses",
    component: () => import("@/views/SubscriptionsLicenses.vue"),
    meta: {
      isSecured: true,
      title: "subscriptions-licenses.title",
      allowedRoles: [
        UserRole.COMPANY_ADMIN,
        UserRole.COMPANY_ADMIN_DASHBOARD,
        UserRole.COMPANY_ADMIN_CHECKLIST,
        UserRole.APP_ADMIN,
        UserRole.LICENCE_MANAGER,
        UserRole.SITE_MANAGER,
        UserRole.STANDARD_USER,
        UserRole.INSTALLER,
      ],
    },
  },
  {
    path: "/vehicle-kpi/",
    name: "VehicleKpi",
    component: () => import("@/views/VehicleKpi.vue"),
    meta: {
      isSecured: true,
      title: "vehicle-kpi.title",
      allowedRoles: [UserRole.APP_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_DASHBOARD, UserRole.SITE_MANAGER, UserRole.STANDARD_USER],
    },
  },
] as RouteRecordRaw[];
