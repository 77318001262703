function downloadFile(blob: Blob, fileName: string) {
  // Download the file
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  // Set the file name.
  link.download = fileName;
  link.click();
  link.remove();
}

export const CSV_SEPARATOR = ";";
export function exportToCSV(data: any[], columns: string[], name?: string) {
  const res = [columns.join(CSV_SEPARATOR), ...data].join("\n");

  const blob = new Blob([res], { type: "text/csv" });
  downloadFile(blob, name || "data.csv");
}
