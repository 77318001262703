<template>
  <v-chip
    class="pa-6"
    :id="props.id"
    :prepend-icon="config.icon"
    :class="config.classes"
  >
    <template v-slot:default>
      <p :id="`${props.id}-label`">{{ props.message }}</p>
    </template>
  </v-chip>
</template>
<script setup lang="ts">
import { computed } from 'vue';

import { ToastType } from '@/store/toast';

const props = defineProps<{
  message: string;
  icon?: string;
  id?: string;
  type: ToastType;
}>();

interface Config {
  icon: string;
  classes: string;
}

const configs: Record<ToastType, Config> = {
  [ToastType.SUCCESS]: {
    icon: "mdi-check",
    classes: "text-accent-700 bg-accent-300",
  },
  [ToastType.ERROR]: {
    icon: "mdi-close-thick",
    classes: "text-primary-900 bg-accent-900",
  },
};

const config = computed(() => configs[props.type]);
</script>
