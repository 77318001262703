import { CompanyFunctionnalityEnum } from "../company-service/models";
import { UserRole } from "../user-service";

export function redirect(role: string | undefined): string {
  switch (role) {
    case UserRole.APP_ADMIN:
      return "/risk-management";
    case UserRole.COMPANY_ADMIN:
      return "/risk-management";
    case UserRole.COMPANY_ADMIN_CHECKLIST:
      return "/dashboard";
    case UserRole.COMPANY_ADMIN_DASHBOARD:
      return "/risk-management";
    case UserRole.SITE_MANAGER:
      return "/risk-management";
    case UserRole.STANDARD_USER:
      return "/risk-management";
    case UserRole.DRIVER:
      return "/profile";
    case UserRole.INSTALLER:
      return "/profile";
    case UserRole.LICENCE_MANAGER:
      return "/requests";
    default:
      return "/sign-in";
  }
}

export function checkCompanyFunctionnalities(companyFunctionalities: string[], role: string): boolean {
  if ([UserRole.APP_ADMIN.toString(), UserRole.LICENCE_MANAGER.toString()].includes(role)) return true;

  // If company has functionatlies
  // AND (can connect to checklist OR can connect to dashboard)
  if (
    companyFunctionalities &&
    //Can connect to checklist
    ((companyFunctionalities.includes(CompanyFunctionnalityEnum.CHECKLIST) &&
      [UserRole.COMPANY_ADMIN_CHECKLIST.toString(), UserRole.COMPANY_ADMIN.toString(), UserRole.DRIVER.toString(), UserRole.INSTALLER.toString()].includes(role)) ||
      //Can connect to dashboard
      (companyFunctionalities.includes(CompanyFunctionnalityEnum.DASHBOARD) &&
        [UserRole.COMPANY_ADMIN_DASHBOARD.toString(), UserRole.STANDARD_USER.toString(), UserRole.SITE_MANAGER.toString(), UserRole.COMPANY_ADMIN.toString()].includes(role)))
  ) {
    return true;
  }

  return false;
}
