import { GeoResult } from "@/helpers/geo-utils";
import { mapCompany } from "../company-service/mapper";
import { UserRole } from "../user-service";
import { Worksite, WorksiteData, WorksiteKPI, WorksiteKPIData } from "./models";

export function mapWorksite(data: any): Worksite {
  if (!data) {
    return null;
  }

  return {
    uuid: data?.uuid,
    name: data?.name,
    address: GeoResult.mapForWorksite(data),
    company: data?.company ? mapCompany(data?.company) : null,
    city: data?.city,
    country: data?.country,
    coordinates: data?.coordinates,
    image_id: data?.image_id,
    image_name: data?.image?.name,
    vehicles_count: data?.vehicles_aggregate?.aggregate?.count || 0,
    user_count: data?.users_aggregate?.aggregate?.count || 0,
    site_managers: data?.users
      ?.filter((el) => el.profile.user.defaultRole === UserRole.SITE_MANAGER)
      ?.map((el) => el.profil_id),
    standard_users: data?.users
      ?.filter((el) => el.profile.user.defaultRole === UserRole.STANDARD_USER)
      ?.map((el) => el.profil_id),
    timezone: data?.timezone,
  };
}

export function mapWorksites(data: any): Worksite[] {
  return data?.worksites?.map(mapWorksite) || [];
}

export function mapWorksiteData(data: any): WorksiteData {
  if (!data) {
    return null;
  }

  return {
    operatingHours: data?.operating_hours_count,
    pedestrianDetections: data?.pedestrian_detections_count,
    vehicleCount: data?.vehicle_count,
    pedestrianDetectionsByOperatingHour:
      data?.pedestrian_detection_per_operating_hour,
    vehicleGraphData: data?.vehicle_graph_data.map((el) => {
      return { count: el.count, category: el.category };
    }),
  };
}

export function mapWorksiteKPIData(data: any): WorksiteKPIData {
  return {
    label: data?.label,
    value: data?.value,
  };
}
export function mapWorksiteKpi(data: any): WorksiteKPI {
  if (!data) {
    return null;
  }

  return {
    utOperatingHours: data?.ut_operating_hours?.map((el) =>
      mapWorksiteKPIData(el)
    ),
    utDetections: data?.ut_detections?.map((el) => mapWorksiteKPIData(el)),
    detectionPerOperatingHour: data?.detection_per_operating_hour?.map((el) =>
      mapWorksiteKPIData(el)
    ),
    dailyDetections: data?.daily_detections?.map((el) =>
      mapWorksiteKPIData(el)
    ),
    monthlyDetections: data?.monthly_detections?.map((el) =>
      mapWorksiteKPIData(el)
    ),
    pickEventsHour: data?.pick_events_hour?.map((el) => mapWorksiteKPIData(el)),
    overview: {
      average_detection:
        data?.overview?.average_detection_per_operating_hour_per_ut,
      average_hourly_detection: data?.overview?.average_hourly_detection,
      average_operating: data?.overview?.average_operating,
      detection: data?.overview?.detection,
      operating: data?.overview?.operating,
      average_daily_detection: data?.overview?.average_daily_detection,
      average_detection_by_hour_by_used_vehicle:
        data?.overview?.average_detection_by_hour_by_used_vehicle,
      average_operating_by_vehicle:
        data?.overview?.average_operating_by_vehicle,
    },
  };
}
