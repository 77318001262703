import VueApollo from "vue-apollo";
import {
  ApolloClient,
  InMemoryCache,
  DefaultOptions,
  split,
  HttpLink,
  ApolloLink,
  fromPromise,
} from "@apollo/client/core";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { GraphQLErrors } from "@apollo/client/errors";
import { useAuthService } from "@/services/auth-service";

const authService = useAuthService();

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_HASURA_GRAPHQL_URL,
  // @ts-ignore
  headers: async () => {
    const token = authService.accessToken();
    return {
      ...(token && {
        authorization: `Bearer ${token}`,
      }),
    };
  },
});

//Declared as var to be set in initWs and closed in restartWs
var socketGlobal: WebSocket | null = null;

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_HASURA_WSS_URL,
    lazy: true,
    connectionParams: async () => {
      const token = authService.accessToken();
      return {
        reconnect: true,
        timeout: 30000,
        headers: {
          ...(token && {
            authorization: `Bearer ${token}`,
          }),
        },
      };
    },
    on: {
      connected: (socket) => {
        socketGlobal = socket as WebSocket;
      },
    },
  })
);

/**
 * This method must be called everytime auth status changes.
 * If not, the WebSocket client will continue to run with the JWT used
 * on client initialization.
 */
export const restartWs = () => {
  socketGlobal?.close(1000, "tokenChanged");
};

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = authService.accessToken();
  operation.setContext({
    headers: {
      ...(token && {
        authorization: `Bearer ${token}`,
      }),
    },
  });

  return forward(operation);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const onErrorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    // Hasura ne retourne pas de 401, il faut donc vérifier si le token est invalide via le code d'erreur graphql
    if (
      hasGraphQLErrors(graphQLErrors) ||
      networkError?.message.includes("connection_error")
    ) {
      return fromPromise(authService.refreshTokenAndGetAccessToken()).flatMap(
        (accessToken) => {
          operation.getContext().headers.Authorization = `Bearer ${accessToken}`;
          return forward(operation);
        }
      );
    }
  }
);

const apolloClient = new ApolloClient({
  link: ApolloLink.from([onErrorLink, authMiddleware, splitLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
  headers: {
    "content-type": "application/json",
    Authorization: `Bearer ${authService.accessToken()}`,
  },
});

function hasGraphQLErrors(graphQLErrors: GraphQLErrors | undefined) {
  return (
    graphQLErrors &&
    graphQLErrors.find(({ extensions }) => extensions.code === "invalid-jwt")
  );
}

export function useCurrentApolloClient() {
  return apolloClient;
}

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

export default apolloProvider;
