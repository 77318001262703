<template>
  <v-btn
    rounded="lg"
    flat
    :class="cla"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    :variant="variant"
    :color="color || 'tertiary-900'"
    :loading="loading"
    :density="density !== undefined ? density : 'default'"
    :disabled="disabled"
  >
    <slot>
      {{ text }}
    </slot>
  </v-btn>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  text?: string;
  bordered?: boolean;
  appendIcon?: string;
  prependIcon?: string;
  variant?: "flat" | "text" | "elevated" | "tonal" | "outlined" | "plain";
  color?: string;
  density?: 'default' | 'comfortable' | 'compact';
  disabled?: boolean;
  loading?: boolean;
  class?: string;
}>();

const cla = computed(() => {
  return "text-none " + props.class;
});
</script>
<style scoped>
.bg-on {
  background-color: var(--v-theme-primary-300);
}

.bg-off {
  background-color: var(--v-theme-primary-300);
}
</style>
