<template>
  <div v-if="bannerVisible" class="bg-primary-500 pa-4 d-flex justify-space-between">
    <div class="d-flex">
      <v-icon icon="mdi-alert-circle-outline" class="mr-2" color="tertiary-900" />
      <p>{{ t("expiration-banner.title") }}</p>
    </div>

    <v-icon icon="mdi-close" @click="bannerVisible = false" />
  </div>
</template>

<script setup lang="ts">
import { getEndingSubscriptionCode } from "@/services/subscription-licence-service";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { authClient } from "@/plugins/nhost";

const { t } = useI18n();
const bannerVisible = ref(false);

authClient.onAuthStateChanged((state) => {
  if (state === "SIGNED_IN") {
    getEndingSubscriptionCode()
      .then((code) => {
        setTimeout(() => {
          bannerVisible.value = Boolean(code.length);
        }, 500);
      })
      .catch((e) => {});
  } else {
    bannerVisible.value = false;
  }
});
</script>
