import { CSV_SEPARATOR, exportToCSV } from "@/helpers/export-utils";
import { mapVehicle, mapVehicleFunctionalities } from "../vehicle-service/mapper";
import { Vehicle, VehicleFunctionnality } from "../vehicle-service/models";
import dayjs from "@/plugins/dayjs";
import { Product } from "../product-service/models";
import { mapProduct } from "../product-service/mapper";
import { mapCompany } from "../company-service/mapper";
import { Company } from "../company-service";

export enum SubscriptionLicenceType {
  SUBSCRIPTION = "subscription",
  LICENCE = "licence",
}

export enum SubscriptionLicenceStatus {
  ACTIVATED = "activated",
  EXPIRED = "expired",
  WAITING_FOR_ASSOCIATION = "waiting_for_association",
}

export class SubscriptionLicenceForm {
  uuid: string;
  type: SubscriptionLicenceType;
  subtype: string;
  quantity: number;
  email: string;
  duration: SubscriptionDuration;
  arc: string;
  client: string;
}

export class Subscription {
  code: string;
  companyId: string;
  vehiculeId: string;
  productId: string;
  startAt: string;
  endAt: string;
  requestId: string;
  userEmail: string;
  arc: string;
  functionalities: VehicleFunctionnality[];

  static map(data: any): Subscription | null {
    if (!data) {
      return null;
    }
    const subscription = new Subscription();
    subscription.code = data.code;
    subscription.companyId = data.company_id;
    subscription.vehiculeId = data.vehicule_id;
    subscription.productId = data.product_id;
    subscription.startAt = data.start_date;
    subscription.endAt = data.end_date;
    subscription.requestId = data.request_id;
    subscription.userEmail = data.user_email;
    subscription.arc = data?.arc;
    subscription.functionalities = mapVehicleFunctionalities(data?.functionalities?.map((el) => el.functionality));
    return subscription;
  }

  static mapAll(data: any[]): Subscription[] {
    if (!data) {
      return [];
    }
    return data.map((d: any) => Subscription.map(d));
  }
}

export class Licence {
  code: string;
  serialNumberTablet: string;
  functionalitiyId: string;
  requestId: string;
  functionality: LicenseFunctionality;

  static map(data: any): Licence | null {
    if (!data) {
      return null;
    }
    const licence = new Licence();
    licence.code = data.code;
    licence.serialNumberTablet = data.serial_number_tablet;
    licence.functionalitiyId = data.functionalitiy_id;
    licence.requestId = data.request_id;
    licence.functionality = LicenseFunctionality.map(data?.functionality);
    return licence;
  }

  static mapAll(data: any[]): Licence[] {
    if (!data) {
      return [];
    }
    return data.map((d: any) => Licence.map(d));
  }
}

export class SubscriptionLicenceRequest {
  id: string;
  arc: string;
  createdAt: Date;
  licences: Licence[] = [];
  subscriptions: Subscription[] = [];

  get type(): string {
    const types = [];
    if (this.subscriptions.length) {
      types.push("Subscriptions");
    }
    if (this.licences.length) {
      types.push("Licences");
    }
    return types.join("/") || "-";
  }

  static map(data: any): SubscriptionLicenceRequest | null {
    if (!data) {
      return null;
    }
    const subscriptionLicense = new SubscriptionLicenceRequest();

    subscriptionLicense.id = data.id;
    subscriptionLicense.createdAt = data?.created_at ? new Date(data.created_at) : null;
    subscriptionLicense.licences = Licence.mapAll(data?.licenses) || [];
    subscriptionLicense.subscriptions = Subscription.mapAll(data?.subscriptions) || [];
    subscriptionLicense.arc = [...new Set(subscriptionLicense.subscriptions.map((el) => el.arc))].filter((el) => el).join(",");
    return subscriptionLicense;
  }

  static mapAll(data: any[]): SubscriptionLicenceRequest[] {
    if (!data) {
      return [];
    }
    return data.map((d: any) => SubscriptionLicenceRequest.map(d));
  }

  toCSV() {
    const headers = ["order code", "type", "functionality", "subscription/licence code"];

    const subscriptionLines = this.subscriptions.map((el) => ["", SubscriptionLicenceType.SUBSCRIPTION, el.functionalities?.map((el) => el?.key)?.join(","), el.code]);
    const licenceLines = this.licences.map((el) => ["", SubscriptionLicenceType.LICENCE, el.functionality?.key, el.code]);
    const lines = [...subscriptionLines, ...licenceLines];
    lines[0][0] = this.id;

    const updatedLines = lines.map((line) => {
      return line.join(CSV_SEPARATOR);
    });

    exportToCSV(updatedLines, headers, `request-${this.id}.csv`);
  }
}

export enum SubscriptionDuration {
  THREE_MONTHS = "3-months",
  ONE_YEAR = "1-year",
  TWO_YEARS = "2-years",
  THREE_YEARS = "3-years",
  FOUR_YEARS = "4-years",
  FIVE_YEARS = "5-years",
}

export class SubscriptionLicence {
  code: string;
  subtype: string;
  product: Product;
  vehicle: Vehicle;
  startDate: Date;
  endDate: Date;
  subscriptionCode: string;
  licenseCode: string;
  company: Company;
  duration: SubscriptionDuration;

  get typeKey(): string {
    if (this.subscriptionCode) {
      return "subscription-type.subscription";
    } else if (this.licenseCode) {
      return "subscription-type.license";
    }
    return "";
  }

  get is3Months(): boolean {
    return this.duration === SubscriptionDuration.THREE_MONTHS;
  }

  get status(): SubscriptionLicenceStatus {
    if (this.licenseCode) {
      if (!this.product?.serialNumber) {
        return SubscriptionLicenceStatus.WAITING_FOR_ASSOCIATION;
      }
      return SubscriptionLicenceStatus.ACTIVATED;
    } else if (this.subscriptionCode) {
      const now = dayjs();
      if (!this.startDate) {
        return SubscriptionLicenceStatus.WAITING_FOR_ASSOCIATION;
      } else if (this.startDate && this.endDate && now.isBefore(this.endDate)) {
        return SubscriptionLicenceStatus.ACTIVATED;
      } else {
        return SubscriptionLicenceStatus.EXPIRED;
      }
    }
  }

  static map(data: any): SubscriptionLicence | null {
    if (!data) {
      return null;
    }
    const subscriptionLicense = new SubscriptionLicence();

    subscriptionLicense.startDate = data?.start_date ? new Date(data.start_date) : null;
    subscriptionLicense.endDate = data?.end_date ? new Date(data.end_date) : null;
    subscriptionLicense.product = mapProduct({ id: data?.product_id, name: data?.product_name, serial_number: data?.product_sn });
    subscriptionLicense.vehicle = mapVehicle({ id: data?.vehicle_id, name: data?.vehicle_name });

    if (data.subscription_code) {
      subscriptionLicense.code = data?.subscription_code;
      subscriptionLicense.subtype = data?.subscription?.functionalities[0]?.functionality?.key;
      subscriptionLicense.subscriptionCode = data?.subscription_code;
      subscriptionLicense.company = mapCompany(data?.subscription?.company);
      subscriptionLicense.duration = data?.subscription?.duration;
    } else {
      subscriptionLicense.code = data?.licence_code;
      subscriptionLicense.licenseCode = data?.licence_code;
      subscriptionLicense.subtype = data?.license?.functionality?.key;
      subscriptionLicense.company = mapCompany(data?.license?.product?.vehicle?.company);
    }

    return subscriptionLicense;
  }

  static mapAll(data: any[]): SubscriptionLicence[] {
    if (!data) {
      return [];
    }
    return data.map((d: any) => SubscriptionLicence.map(d));
  }
}

export class LicenseFunctionality {
  id: string;
  key: string;

  static map(data: any): LicenseFunctionality | null {
    if (!data) {
      return null;
    }
    const licenseFunctionality = new LicenseFunctionality();
    licenseFunctionality.id = data.id;
    licenseFunctionality.key = data.key;
    return licenseFunctionality;
  }

  static mapAll(data: any[]): LicenseFunctionality[] {
    if (!data) {
      return [];
    }
    return data.map((d: any) => LicenseFunctionality.map(d));
  }
}

export class NetsuiteArcResult {
  client: string;
  is_valid: boolean;

  static map(data: any): NetsuiteArcResult | null {
    if (!data) {
      return null;
    }
    const result = new NetsuiteArcResult();
    result.is_valid = data.is_valid;
    result.client = data.client;
    return result;
  }
}
