function set(key: string, value: string) {
  localStorage.setItem(key, value);
}

function get(key: string): string | null {
  return localStorage.getItem(key);
}

function remove(key: string) {
  return localStorage.removeItem(key);
}

export function useStorageDao() {
  return {
    set,
    get,
    remove,
  };
}
