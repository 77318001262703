import { i18n } from "@/plugins/i18n";
import { computed } from "vue";
import { SubscriptionDuration } from "@/services/subscription-licence-service/models";

export const subscriptionDurationValues = computed((): { value: SubscriptionDuration; label: string }[] => [
  {
    value: SubscriptionDuration.THREE_MONTHS,
    label: i18n.global.t("request.form.month-duration-label", { count: 3 }),
  },
  {
    value: SubscriptionDuration.ONE_YEAR,
    label: i18n.global.t("request.form.year-duration-label", { count: 1 }),
  },
  {
    value: SubscriptionDuration.TWO_YEARS,
    label: i18n.global.t("request.form.year-duration-label", { count: 2 }),
  },
  {
    value: SubscriptionDuration.THREE_MONTHS,
    label: i18n.global.t("request.form.year-duration-label", { count: 3 }),
  },
  {
    value: SubscriptionDuration.FOUR_YEARS,
    label: i18n.global.t("request.form.year-duration-label", { count: 4 }),
  },
  {
    value: SubscriptionDuration.FIVE_YEARS,
    label: i18n.global.t("request.form.year-duration-label", { count: 5 }),
  },
]);

export function getMonthsFromSubscriptionDuration(duration: SubscriptionDuration): number {
  switch (duration) {
    case SubscriptionDuration.THREE_MONTHS:
      return 3;
    case SubscriptionDuration.ONE_YEAR:
      return 12;
    case SubscriptionDuration.TWO_YEARS:
      return 24;
    case SubscriptionDuration.THREE_YEARS:
      return 36;
    case SubscriptionDuration.FOUR_YEARS:
      return 48;
    case SubscriptionDuration.FIVE_YEARS:
      return 60;
    default:
      return null;
  }
}
