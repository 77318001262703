<template>
  <v-menu location="end">
    <template v-slot:activator="{ props, isActive }" v-if="route?.to || route?.children?.length > 0">
      <div :class="isOnMenu || isActive ? 'active' : ''" class="clickable">
        <v-img @click="navigate(route)" :src="`/images/drawer/${route.icon}.svg`" alt="Logo" :width="25" class="my-7 overflow-visible" :class="isOnMenu || isActive ? 'ml-1' : ''" v-bind="props">
          <v-icon icon="mdi-circle" color="red" size="10" class="red-dot-image" v-if="route.badge"/>
        </v-img>
      </div>
    </template>

    <v-list v-if="route.children" class="ml-4 bg-primary-500">
      <v-list-item v-for="subRoute of route.children" :key="subRoute.id" :value="subRoute.title" class="navbar-item" @click="navigate(subRoute)">
        <template v-slot:title>
          <div class="d-flex align-center">
            <p class="navbar-item-text">{{ subRoute.title }}</p>
            <v-icon icon="mdi-circle" color="red" size="10" class="ml-2" v-if="route.badge || route.children.some((child) => child.badge)"/>
          </div>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { Route } from "@/services/common-service/models";
import { computed } from "vue";

const props = defineProps<{
  route: Route;
  rail: boolean;
}>();

const router = useRouter();
const navigate = (route: Route) => {
  if (route?.children) {
    return;
  }
  router.push(route.to);
};

const isOnMenu = computed(() => {
  return props?.route?.to === router.currentRoute.value.path || props.route?.children?.some((child) => child.to === router.currentRoute.value.path);
});
</script>

<style scoped lang="scss">
.active {
  border-left: solid;
  border-color: rgba(var(--v-theme-tertiary-900), 1);
}

.clickable:hover {
  cursor: pointer;
}

.red-dot-image {
  position: absolute;
  right: -5px;
  top: -5px;
}

.red-dot {
  position: absolute;
  right: -15px;
  top: 0px;
}
</style>
