import { createApp } from "vue";
import App from "./App.vue";
import "./index.scss";
import { DefaultApolloClient } from "@vue/apollo-composable";
import router from "@/router";
import apolloProvider from "@/plugins/apollo";
import { createPinia } from "pinia";
import { i18n } from "@/plugins/i18n";
import Vue3Toasity from "vue3-toastify";
import vuetify from "@/plugins/vuetify";
import * as Sentry from "@sentry/vue";

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  allowUrls: ["https://460-review-dashboard-q6y6ik.checklist.blaxtair.com"],
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "https://460-review-dashboard-q6y6ik.checklist.blaxtair.com/",
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app
  .use(createPinia())
  .use(i18n)
  .use(vuetify)
  .use(Vue3Toasity, { autoClose: 3000 })
  .use(router)
  .provide(DefaultApolloClient, apolloProvider.defaultClient)
  .mount("#app");
